<template>
  <section class="un-available">
    <h1 class="h1">
      <slot name="h1txt">
        GOURMET RESTAURANT
        <span class="nobreak">
          (1. OG)
        </span>
        BIS
        <span class="nobreak">
          18. AUGUST
        </span>
        GESCHLOSSEN
      </slot>
    </h1>
    <div class="p-wrap">
      <slot name="paragraphs">
        <p>
          Ab Mittwoch
          <span class="nobreak">
            04. August
          </span>
          verwöhnen wir Sie aber am Abend wieder im Bar/Restaurant im Erdgeschoss und auf unserer Terrasse mit einem sommerlichen Menu.
        </p>
      </slot>
    </div>
    <!--
    <div class="block">
      <LinkList :link-arr="togoLinks" />
    </div>
    -->
  </section>
</template>

<script>
/*
import LinkList from "@/components/LinkList.vue"
import {navLinksTogo} from "@/models/LinkData.js"
*/

export default {
  /*
  components: {
    LinkList
  },

  data() {
    return {
      togoLinks: navLinksTogo
    }
  }
  */
}
</script>

<style lang="scss" scoped>
@use "vars";

.un-available {
  padding: 2em vars.$body-padding-h;
  text-align: center;
  color: vars.$white;
  background-color: vars.$beige;
}

.nobreak {
  white-space: nowrap;
}

.h1 {
  font-size: 1.2em;
  color: vars.$gold;
}

.p-wrap {
  p {
    margin-top: 1em;
  }
}

.block {
  margin-top: 1em;
  font-family: vars.$font-fam-deco;
  text-transform: uppercase;
}
</style>
